<template>
  <div class="content-accounts-receivable-table-all">
    <!-- <div
      v-for="(item, i) in aTables"
      :key="i"
      class="content-accounts-receivable-table"
    > -->
    <v-data-table
      mobile-breakpoint="0"
      :headers="aHeaders"
      :disable-sort="true"
      :items="aTables"
      :loading="bLoadingTable"
      loading-text="Cargando datos..."
      :items-per-page="-1"
      :hide-default-footer="true"
      no-data-text="No hay información disponible para mostrar."
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }"
      class="global-table table-accounts-receivable"
    >
      <template slot="progress">
        <v-progress-linear
          color="var(--primary-color-loading-table)"
          indeterminate
        ></v-progress-linear>
      </template>

      <template v-slot:footer>
        <footer-table-component-global
          :numPages="numPages"
          :totalItems="totalItems"
          @changeTable="getEmitAccountsReceivable"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    aTables: Array,
    aHeaders: Array,
    bLoadingTable: Boolean,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      iItemsPerPage: 5,
    };
  },
  methods: {
    setOrder(oItem) {
      this.$emit("setOrder", oItem);
    },
    getEmitAccountsReceivable(obj) {
      this.$emit("getEmitAccountsReceivable", obj);
    },
  },
};
</script>
<style scoped>
.content-accounts-receivable-table-all {
  background-color: var(--primary-color-background-table) !important;
  border-radius: 15px;
  margin-bottom: 25px !important;
}

.table-accounts-receivable {
  box-shadow: 0px 0px 0px 0px !important;
  background-color: transparent !important;
}

.content-total-amount-pay-all {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
  /* border-bottom: 1px solid var(--primary-color-divider); */
}

.content-txt-price-total-amount-pay-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  padding: 0px 20px;
  min-width: 450px;
  max-width: 450px;
  min-height: 40px;
  background-color: var(--primary-color-background-blue-transparent);
  border-radius: 15px;
}

.txt-total-amount-pay {
  color: var(--primary-color-text-white);
  font-family: "pop-SemiBold";
  font-size: 14px;
  margin-bottom: 0px;
}
.price-total-amount-pay {
  color: var(--primary-color-text-white);
  font-family: "pop-SemiBold";
  font-size: 14px;
  margin-bottom: 0px;
}
</style>

<style>
.header-table-number-account-receivable {
}
.body-table-number-account-receivable {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
}

.body-table-folio-account-receivable {
  min-width: 180px !important;
  width: 180px !important;
  max-width: 180px !important;
}

.body-table-client-account-receivable {
  min-width: 100px !important;
}

.body-table-date-account-receivable {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
}

.body-table-initial-quantity-account-receivable {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
}

.header-table-collect-quantity-account-receivable {
  padding-right: 40px !important;
}
.body-table-collect-quantity-account-receivable {
  min-width: 220px !important;
  padding-right: 40px !important;

  width: 220px !important;
  max-width: 220px !important;
}

.body-table-name-account-receivable {
}

.body-table-date-range-account-receivable {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.body-table-variation-account-receivable {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}

.body-table-payable-total-debit-account-receivable {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}
</style>
